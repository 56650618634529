<script setup lang="ts">
// NOTE: NuxtLinkが component として認識されないため明示的にインポート
// https://github.com/nuxt/nuxt/issues/13659#issuecomment-1596302040
import { NuxtLink } from '#components'
import type { NuxtLinkProps } from '#app'
import type { IconName } from './KsIcon.vue'

const styles = useCssModule()

// FIXME: withDefaults で型が壊れるので、link や nuxtLink を使うときは明示的に `type` の指定を推奨
// a や NuxtLink に不要な属性を渡さないため
// https://github.com/vuejs/core/issues/12106
type Props = CommonProps & (ButtonProps | LinkProps | NuxtLinkPropsType)

type CommonProps = {
  color: 'default' | 'secondary'
  iconName?: IconName
}

type ButtonProps = {
  type: 'button'
  buttonType?: 'submit' | 'button'
  href?: never
  to?: never
}

type LinkProps = {
  type: 'link'
  href: string
  buttonType?: never
  to?: never
}

type NuxtLinkPropsType = {
  type: 'nuxtLink'
  to: NuxtLinkProps['to']
  buttonType?: never
  href?: never
}

const props = withDefaults(defineProps<Props>(), {
  type: 'button',
  color: 'default',
  buttonType: 'button',
  iconName: undefined,
  to: undefined,
  href: undefined,
})

const emit = defineEmits(['click'])

const handleClick = (event: Event) => {
  emit('click', event)
}

const isLink = computed(() => !!props.to || !!props.href)

const componentType = computed(() => {
  if (isLink.value) {
    return props.to ? NuxtLink : 'a'
  }
  return 'button'
})

const buttonClass = computed(() => {
  return [
    styles.button,
    { [styles.default]: props.color === 'default' },
    { [styles.secondary]: props.color === 'secondary' },
    { [styles.iconLink]: isLink.value },
  ]
})
</script>

<template>
  <component
    :is="componentType"
    :to="props.to"
    :href="props.href"
    :type="props.type === 'button' ? props.buttonType : undefined"
    :class="buttonClass"
    @click="handleClick"
  >
    <KsIcon
      v-if="iconName"
      :name="iconName"
      color="#FFF"
      :class="styles.icon"
    />
    <slot />
  </component>
</template>

<style lang="scss" module>
.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  padding: 14px 22px;
  border: 2px solid var(--secondary-color-code);
  border-radius: 4px;

  font-weight: bold;
  font-size: 12px;
  line-height: 1.5;
  text-decoration: none;

  transition:
    background-color 0.3s,
    color 0.3s;

  &.default {
    background-color: #fff;
    color: var(--secondary-color-code);
  }

  &.secondary {
    background-color: var(--secondary-color-code);
    color: #fff;
  }

  .icon {
    display: inline-flex;
    align-items: center;
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }

  @media (hover: hover) {
    &.default:hover {
      background-color: var(--secondary-color-code);
      color: #fff;

      path {
        fill: #fff;
      }
    }
    &.secondary:hover {
      background-color: #fff;
      color: var(--secondary-color-code);

      path {
        fill: var(--secondary-color-code);
      }
    }
  }

  @include mq() {
    padding: 6px 14px;
  }
}
</style>
